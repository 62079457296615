@font-face {
  font-family: "Jost";
  src: url("./assets/fonts/Jost-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Jost-italic";
  src: url("./assets/fonts/Jost-Italic-VariableFont_wght.ttf");
}

body {
  margin: 0;
  font-family: "Jost", "Jost-italic";
}
